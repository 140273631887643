import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sinarmasLogo from '../assets/sinarmasLogo.svg';
import warroomIcon from '../assets/warroomIcon.svg';
import investorIcon from '../assets/investorIcon.svg';
import './Homepage.css';
import { logout } from '../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useDocumentTitle from '../hooks/useDocumentTitle';

const Homepage = () => {
  useDocumentTitle('Home');
  const navigate = useNavigate();

  const [isInvestor, setIsInvestor] = useState(null);
  const [isProduction, setIsProduction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  console.log(user);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
    navigate('/login');
  };

  const handleClick = (path) => {
    navigate(`/war-room/${path}`);
  };

  const setRole = () => {
    if(user.role.production){
      setIsProduction(user.role.production.name);
    }
    if(user.role.investor){
      setIsInvestor(user.role.investor.name);
    }
  }

  useEffect(() => {
    if(user){
      setRole();
    }
  }, [user])

  const listDashboard = [
    {
      title: 'MVD Production',
      icon: warroomIcon,
      params: 'mvd-production',
      show: isProduction
    },
    {
      title: 'MVD Investor',
      icon: warroomIcon,
      params: 'mvd-investor',
      show: isInvestor
    }
  ]

  if (isLoading) return null;

  return (
    <div className="homepage">
      <div className="banner">
        <img src={sinarmasLogo} alt="Sinarmas Logo" />
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <hr className="separator-line" />
      <div className="homepage-container">
        {
          listDashboard.map((dashboard, index) => {
            return (
              dashboard.show &&
              <div key={index} className="box" onClick={() => handleClick(dashboard.params)}>
                <div className="box-title">{dashboard.title}</div>
                <img src={dashboard.icon} alt="Warroom Icon" className="box-icon" />
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Homepage;